import axios from 'axios';
import authHeader from '../services/auth.header';

const usuario = JSON.parse(localStorage.getItem('usuario'));
const perfil = JSON.parse(localStorage.getItem('perfil'));
const token = localStorage.getItem('access_token');

const initialState = usuario
    ? { status: { loggedIn: true }, usuario, perfil, token }
    : { status: { loggedIn: false }, usuario: null, perfil: null, token: null };

const handleSuccess = (state, user) => {
    state.auth.status.loggedIn = true;
    state.usuario = user.usuario;
    state.perfil = user.perfil;
    state.token = user.access_token;
};

const resetState = (state) => {
    state.auth.status.loggedIn = false;
    state.usuario = null;
    state.token = null;
};

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        // login({ commit }, user) {
        //     commit('loginSuccess');
        //     window.location.href = '/';
        // },
        logout({ commit }) {
            axios.get('/api/logout', { headers: authHeader() })
            .then(() => {
                localStorage.removeItem('usuario');
                localStorage.removeItem('perfil');
                localStorage.removeItem('access_token');
                localStorage.removeItem('token_type');
                commit('logout');
                window.location.href = '/';
            }).catch(e => {
                const { message } = e.response.data;
                localStorage.removeItem('usuario');
                localStorage.removeItem('perfil');
                localStorage.removeItem('access_token');
                localStorage.removeItem('token_type');
                commit('logout');
                console.log(message);
            });
        },
    },
    mutations: {
        loginSuccess(state, user) {
            handleSuccess(state, user);
        },
        loginFailure(state) {
            resetState(state);
        },
        registerSuccess(state, user) {
            handleSuccess(state, user);
        },
        registerFailure(state) {
            resetState(state);
        },
        logout(state) {
            resetState(state);
        }
    }
};
