<template>
	<div>
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: 'App',
	data() {
		return {
			activeMenu: '',
		}
	},
	components: {
	},
	mounted() {
		this.scrollToTop();
		this.checkAuth();
	},
	created(){
		this.activeMenu = window.location.pathname;
	},
	methods: {
		scrollToTop() {
			window.scrollTo(0,0);
		},
		checkAuth() {
			if (localStorage.getItem('access_token') === null) {
				console.log('no auth token')
			}
			else {
				console.log('auth token')
			}
		},
	},
}
</script>

<style></style>