import { createStore } from 'vuex'
import { auth } from "./auth.module";
import axios from 'axios';
import authHeader from '../services/auth.header';

export default createStore({
    state: {
        company: {
            name: 'PuraVida Sports',
        },
    },
    mutations: {
        hacerAlgo(state, val) {
            console.log(val + ' hacerAlgo');
        },
        setUser(state, user) {
            state.auth.status.loggedIn = true;
            state.usuario = user;
            state.token = user.token;
            localStorage.setItem('usuario', JSON.stringify(user));
            localStorage.setItem('perfil', JSON.stringify(user.perfil));
            localStorage.setItem('access_token', user.token);
            localStorage.setItem('token_type', user.tipo);
            if(user.foto){
                localStorage.setItem('foto', user.foto);
            }
        },
        logoutUser() {
            axios.get('/api/front/logout', { headers: authHeader() })
            .then(response => {
                console.log(response.data);
                localStorage.removeItem('usuario');
                localStorage.removeItem('perfil');
                localStorage.removeItem('access_token');
                localStorage.removeItem('token_type');
                localStorage.removeItem('foto');
                window.location.href = '/';
            }).catch(e => {
                const { message } = e.response.data;
                console.log(message);
                localStorage.removeItem('usuario');
                localStorage.removeItem('perfil');
                localStorage.removeItem('access_token');
                localStorage.removeItem('token_type');
                localStorage.removeItem('foto');
                window.location.href = '/';
            });
        },
        getPerfil(state) {
            axios.get('/api/front/get-perfil', { headers: authHeader() })
            .then(response => {
                localStorage.removeItem('perfil');
                localStorage.setItem('perfil', JSON.stringify(response.data.perfil));
                state.auth.perfil = response.data.perfil;
            }).catch(e => {
                const { message } = e.response.data;
                console.log(message);
            });
        }
        // setAuth(state, auth) {
        //     state.auth.status.loggedIn = auth;
        // },
    },
    getters: {
        getUser(state) {
            return state.auth.usuario;
        },
        getPerfil(state) {
            return state.auth.perfil;
        },
        isAuthenticated(state) {
            return state.auth.status.loggedIn;
        },
    },
    modules: {
        auth
    },
});
