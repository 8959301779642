export default function authHeader() {
    let access_token = localStorage.getItem('access_token');

    if (access_token) {
        return {
            Authorization: 'Bearer ' + access_token,
            Accept: "application/json",
            'Full-Header': 'PuravidaUser',
        };
    } else {
        return {
            Accept: "application/json",
            'Full-Header': 'PuravidaGuest',
        };
    }
}
